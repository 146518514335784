import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Form = _resolveComponent("Form")!
  const _component_History = _resolveComponent("History")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Inventory" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_ctx.auth_user?.isAdmin())
              ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 10 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Select Retailer" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              id: "retailer_id",
                              value: _ctx.retailer_id,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.retailer_id) = $event)),
                              required: "",
                              onChange: _ctx.onChange,
                              "show-search": "",
                              filterOption: _ctx.filterOption
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailers, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.full_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "onChange", "filterOption"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      span: 10,
                      offset: 2
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Select Site" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              id: "site_id",
                              value: _ctx.site_id,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.site_id) = $event)),
                              required: "",
                              onChange: _ctx.onChangeSite,
                              "show-search": "",
                              filterOption: _ctx.filterOption
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailer_sites, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.site_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "onChange", "filterOption"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_a_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Select Site" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              id: "site_id",
                              value: _ctx.site_id,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.site_id) = $event)),
                              required: "",
                              onChange: _ctx.onChangeSite
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailer_sites, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.site_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "onChange"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })),
            _createVNode(_component_DataTable, {
              loading: _ctx.loading == 'loading',
              data: _ctx.items.data,
              columns: _ctx.fields,
              pagination: _ctx.items,
              onFilter: _ctx.filterRecord,
              onEncode: _ctx.editRecord,
              onPost: _ctx.postRecord,
              onHistory: _ctx.historyRecord,
              showCreateButton: false,
              showEditButton: false,
              showDeleteButton: false,
              showEncodeButton: true,
              showPostButton: true,
              showHistoryButton: true
            }, null, 8, ["loading", "data", "columns", "pagination", "onFilter", "onEncode", "onPost", "onHistory"]),
            _createVNode(_component_Form, {
              title: "Encode",
              visible: _ctx.visible,
              onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.visible = false)),
              onSave: _ctx.handleSaving,
              data: _ctx.form,
              id: _ctx.form.id
            }, null, 8, ["visible", "onSave", "data", "id"]),
            _createVNode(_component_History, {
              title: "Inventory Movement",
              visible: _ctx.visible_history,
              onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.visible_history = false)),
              onSave: _ctx.handleSaving,
              data: _ctx.form,
              "site-product-id": _ctx.form.site_product_id,
              "site-id": _ctx.site_id
            }, null, 8, ["visible", "onSave", "data", "site-product-id", "site-id"])
          ])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }))
}