import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible_adjustment,
    title: _ctx.title,
    width: "800px",
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        loading: _ctx.loading == 'loading',
        data: _ctx.items.data,
        columns: _ctx.fields,
        pagination: _ctx.items,
        showCreateButton: false,
        onFilter: _ctx.filterRecord
      }, null, 8, ["loading", "data", "columns", "pagination", "onFilter"])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}