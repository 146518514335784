
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { defineComponent, ref, onUpdated, computed, onMounted, inject } from 'vue'
import moment, { Moment, now } from 'moment'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Form',
    },
    data: {
      type: Object,
    },
    inventoryDate: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const form = ref(props.data)
    const dateFormat = 'YYYY/MM/DD'
    const category_type = ref('')
    const adjustment = inject<any>('adjustment')
    const inventoryDate = ref(props.inventoryDate)

    const disabledDate = (current: Dayjs) => {
      dayjs.extend(utc)
      dayjs.extend(timezone)

      const formatted = dayjs(inventoryDate.value, 'YYYY-MM-DD').tz('Asia/Manila')

      return current < formatted.clone().subtract(2, 'day') || current >= formatted.startOf('day')
    }

    const getAllAdjustmentCategories = () => {
      store.dispatch('Option/getAllAdjustmentCategories')
    }

    const getCategoryType = (id: any) => {
      store.dispatch('AdjustmentCategory/show', id).then((res) => (
          category_type.value = res.data.type
      ))
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const all_adjustment_categories = computed(() => store.getters['Option/all_adjustment_categories'])

    onMounted(getAllAdjustmentCategories)
    onUpdated(() => {
      category_type.value = adjustment.category_type
    })

    return {
      form,
      dateFormat,
      moment,
      value1: ref<Moment>(moment(new Date(), dateFormat)),
      all_adjustment_categories,
      category_type,
      adjustment,
      getCategoryType,
      disabledDate,
      filterOption
    }
  },
})
