
import { defineComponent, ref, computed } from 'vue'
import moment, { Moment } from 'moment'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    visible_remarks: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Form',
    },
    data: {
      type: Object,
    },
  },
  setup (props) {
    const store = useStore()
    const form = ref(props.data)
    return {
      form,
    }
  },
})
