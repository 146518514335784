
import Card from '@/components/Card.vue'
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  toRefs,
  createVNode,
  h,
  provide,
  onBeforeUpdate
} from 'vue'
import { useStore } from 'vuex'
import Form from './Form.vue'
import History from './History.vue'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import axios from '../../../../axios'
import Swal from 'sweetalert2'
export default defineComponent({
  components: {
    Card,
    Form,
    History
  },
  setup () {
    const store = useStore()
    const form_action = ref('save')
    const visible = ref(false)
    const visible_history = ref(false)
    const isLoading = ref<boolean>(false)
    const fields = ref([
      {
        title: 'Site Name',
        dataIndex: 'site.site_name',
      },
      {
        title: 'Tank Name',
        dataIndex: 'site_product.tank.name',
      },
      {
        title: 'Product Name',
        dataIndex: 'site_product.product.product_name',
      },
      {
        title: 'Date',
        dataIndex: 'inventory_date',
      },
      {
        title: 'Opening Stock',
        dataIndex: 'opening_stock',
      },
      {
        title: 'Deliveries',
        dataIndex: 'deliveries',
      },
      {
        title: 'Adjustment',
        dataIndex: 'adjustments',
      },
      {
        title: 'Sales',
        dataIndex: 'sales',
      },
      {
        title: 'Closing Stock',
        dataIndex: 'closing_stock',
      },
      {
        title: 'Book Stock',
        dataIndex: 'book_stock',
      },
      {
        title: 'Variance',
        dataIndex: 'variance',
      },
      {
        title: '%',
        dataIndex: 'variance_percentage',
      },
      {
        title: 'Action',
        dataIndex: 'inventory',
        slots: {customRender: 'inventory'},
        align: 'center'
      },
    ])
    const site_id = ref('')
    const form = reactive({
      id: '',
      sales: '',
      adjustments: '',
      deliveries: '',
      closing_stock: '',
      site_id: '',
      site_name: '',
      grade: '',
      tank: '',
      deliveries_remark: '',
      sales_remark: '',
      opening_stock: 0,
      other_remark: '',
      closing_stock_remark: '',
      site_product_id: '',
      date: '',
      adjustment_entries: [],
      book_stock: '',
      variance: ''
    })

    const user = reactive({
      id: '',
      role: ''
    })

    provide('inventory', form)

    const getAllRetailers = () => {
      store.dispatch('Option/getAllRetailers')
    }

    const getAllSitesPerRetailer = (payload: any) => {
      store.dispatch('Option/getAllSitesPerRetailer', payload)
    }

    const index = (payload: any) => {
      store.dispatch('Inventories/index', payload).then(() => {
        isLoading.value = false
      })
    }

    const getAllRetailerSites = () => {
      store.dispatch('Option/getAllRetailerSites')
    }

    const onChange = (payload: any) => {
      getAllSitesPerRetailer({
        ...payload,
        selected_retailer: payload
      })
    }

    const onChangeSite = (payload: any) => {
      site_id.value = payload
      index({
        selected_site: site_id.value
      })
    }

    const filterRecord = (payload: any) => {
      index({selected_site: site_id.value, payload})
    }

    const handleSaving = (record: any) => {
      updateRecord(record)
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('Inventories/update', payload)
          .then(() => (
              visible.value = false,
                  index({selected_site: site_id.value, payload})
          ))
    }

    const postRecord = (record: any) => {
      if (!record.sales || !record.closing_stock || !record.deliveries) {
        missingRequiredData(record)
      } else {
        confirmPost(record)
      }
    }

    const historyRecord = (record: any) => {
      const {site_product_id, site_id} = toRefs(form)
      site_product_id.value = record.site_product.id
      site_id.value = record.site_id
      visible_history.value = true
    }

    const missingRequiredData = (payload: any) => {
      if(payload.sales == null){
        Swal.fire({
          title: 'Missing required field',
          text:  'Sales input is required',
          icon: 'info',
          confirmButtonText: 'OK',
        })
      }else if(payload.closing_stock == null){
        Swal.fire({
          title: 'Missing required field',
          text:  'Closing Stock input is required',
          icon: 'info',
          confirmButtonText: 'OK',
        })
      }else if(payload.deliveries == null){
        Swal.fire({
          title: 'Missing required field',
          text:  'Delivery input is required',
          icon: 'info',
          confirmButtonText: 'OK',
        })
      }
              
      // Modal.info({
      //   title: 'Missing required fields',
      //   icon: createVNode(ExclamationCircleOutlined),
      //   content: h('div', {}, [
      //     h('p', payload.sales == null ? 'Sales input is required' : ''),
      //     h('p', payload.deliveries == null ? 'Delivery input is required' : ''),
      //     h('p', payload.closing_stock == null ? 'Closing Stock input is required' : '')
      //   ]),
      // })
    }

    const confirmPost = (payload = {}) => {
      Swal.fire({
        title: 'Confirm Post',
        text: 'Are you sure you want to POST this Daily Inventory?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          isLoading.value = true
          store.dispatch('Inventories/store', payload).then(() => (
            index({selected_site: site_id.value})
          )).catch(() => {
            isLoading.value = false
            index({selected_site: site_id.value})
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            isLoading.value = false
            index({selected_site: site_id.value})
        }
      });
      // Modal.confirm({
      //   title: 'Confirm Post',
      //   icon: createVNode(ExclamationCircleOutlined),
      //   content: createVNode('div', {style: 'color:red;'}, 'Are you sure you want to POST this Daily Inventory?'),
      //   onOk () {
      //     isLoading.value = true
      //     store.dispatch('Inventories/store', payload).then(() => (
      //       index({selected_site: site_id.value})
      //     )).catch(() => {
      //       isLoading.value = false
      //       index({selected_site: site_id.value})
      //     })
      //   },
      //   class: 'test',
      // })
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {
        id,
        site_product_id,
        sales,
        adjustments,
        deliveries,
        closing_stock,
        site_id,
        site_name,
        grade,
        tank,
        deliveries_remark,
        sales_remark,
        opening_stock,
        other_remark,
        closing_stock_remark,
        date,
        book_stock,
        variance
      } = toRefs(form)
      id.value = record.id
      sales.value = record.sales
      adjustments.value = record.adjustments
      closing_stock.value = record.closing_stock
      deliveries.value = record.deliveries
      site_id.value = record.site_id
      site_product_id.value = record.site_product.id
      site_name.value = record.site.site_name
      grade.value = record.site_product.product.product_name
      tank.value = record.site_product.tank.name
      deliveries_remark.value = record.deliveries_remark
      sales_remark.value = record.sales_remark
      opening_stock.value = record.opening_stock
      other_remark.value = record.other_remark
      closing_stock_remark.value = record.closing_stock_remark
      date.value = record.inventory_date
      book_stock.value = record.book_stock
      variance.value = record.variance
      visible.value = true
    }

    const getUser = async () => {
      const url = 'auth/user'
      const response = await axios.get(url)
      const user = response.data.data

      const {role} = toRefs(user)
      role.value = user.role.view
      if (role.value == 'Admin') {
        getAllRetailers()
      } else {
        getAllRetailerSites()
      }

    }

    const items = computed(() => store.getters['Inventories/items'])
    const all_retailers = computed(() => store.getters['Option/all_retailers'])
    const all_retailer_sites = computed(() => store.getters['Option/all_retailer_sites'])
    const auth_user = computed(() => store.getters['AuthUser/user'])

    onMounted(() => {
      getUser()

      let record = items.value
      if (record.data) {
        record.data.length = 0
      }

    })
    onBeforeUpdate(() => {
      let record = items.value
      if (record.data) {
        record.data.length = 0
      }

    })

    return {
      fields,
      all_retailers,
      all_retailer_sites,
      items,
      site_id,
      form,
      form_action,
      visible,
      visible_history,
      auth_user,
      user,
      onChange,
      onChangeSite,
      filterRecord,
      editRecord,
      handleSaving,
      postRecord,
      historyRecord,
      isLoading,
      filterOption
    }
  }

})
