
import { defineComponent, ref, computed,onUpdated } from 'vue'
import moment, { Moment } from 'moment'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    visible_adjustment: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Form',
    },
    data: {
      type: Object,
    },
  },
  setup (props) {
    const store = useStore()
    const form = ref<any>(props.data)
    const fields = ref([
      {
        title: 'Date',
        dataIndex: 'created_at',
      },
      {
        title: 'Category',
        dataIndex: 'adjustment_category.name',
      },
      {
        title: 'Type',
        dataIndex: 'adjustment_category.type',
      },
      {
        title: 'For Date',
        dataIndex: 'for_date',
      },
      {
        title: 'Value',
        dataIndex: 'value',
      },
      {
        title: 'Adjusted By',
        dataIndex: 'adjusted_by.full_name',
      },
    ])
    const index = (payload: any) => {
      payload = {
        page: 1,
        search: payload.search,
        inventory_id: form.value.id,
      }

      store.dispatch('InventoryAdjustment/index', payload)
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const items = computed(() => store.getters['InventoryAdjustment/items'])
    const loading = computed(() => store.getters['InventoryAdjustment/status'])

    onUpdated(() => {
      index({})
    })
    return {
      form,
      items,
      loading,
      fields,
      filterRecord
    }
  },
})
