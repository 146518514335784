import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_month_picker = _resolveComponent("a-month-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Remarks = _resolveComponent("Remarks")!
  const _component_adjustment_list = _resolveComponent("adjustment-list")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.historyOpen,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.historyOpen) = $event)),
    title: _ctx.title,
    width: "100%",
    closable: true,
    onCancel: _cache[6] || (_cache[6] = ($event: any) => (_ctx.cancel(_ctx.items.data))),
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('save', _ctx.form)), ["prevent"])),
        id: "historyForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 6 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "Month" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_month_picker, {
                        value: _ctx.month,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.month) = $event)),
                        placeholder: "Select month"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 6 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    key: "back",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getData(_ctx.form)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Get Data ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_DataTable, {
            loading: _ctx.loading == 'loading',
            data: _ctx.items.data,
            columns: _ctx.fields,
            pagination: _ctx.items,
            showCreateButton: false,
            showEditButton: false,
            showDeleteButton: false,
            showRemarksButton: true,
            showAdjustmentsButton: true,
            onRemark: _ctx.showRemarks,
            onAdjustment: _ctx.showAdjustment,
            onFilter: _ctx.filterRecord
          }, null, 8, ["loading", "data", "columns", "pagination", "onRemark", "onAdjustment", "onFilter"]),
          _createVNode(_component_Remarks, {
            title: "Remarks",
            visible: _ctx.visible_remarks,
            onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible_remarks = false)),
            data: _ctx.form_fields
          }, null, 8, ["visible", "data"]),
          _createVNode(_component_adjustment_list, {
            title: "Adjustment List",
            visible: _ctx.visible_adjustment,
            onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.visible_adjustment = false)),
            data: _ctx.form_fields
          }, null, 8, ["visible", "data"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}