
import { defineComponent, ref, computed, toRefs, onBeforeUpdate, reactive } from "vue";
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import Remarks from './Remarks.vue'
import AdjustmentList from './AdjustmentList.vue'

export default defineComponent({
  components: {
    Remarks,
    AdjustmentList
  },
  props: {
    visible_history: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Form",
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const form = ref(props.data);
    const siteId = ref<string>('')
    const siteProductId = ref<string>('')
    const form_fields = reactive({
      id: "",
      deliveries_remark:"",
      sales_remark:"",
      other_remark:"",
      closing_stock_remark:"",
    });
    const historyOpen = ref(props.visible_history);
    const visible_remarks = ref(false);
    const visible_adjustment = ref(false);
    const month = ref<Moment>(moment(new Date(), 'YYYY-MM'));
    const fields = ref([
      {
        title: "Site Name",
        dataIndex: "site.site_name",
      },
      {
        title: "Tank Name",
        dataIndex: "site_product.tank.name",
      },
      {
        title: "Product Name",
        dataIndex: "site_product.product.product_name",
      },
      {
        title: "Date",
        dataIndex: "inventory_date",
      },
      {
        title: "Opening Stock",
        dataIndex: "opening_stock",
      },
      {
        title: "Delivery",
        dataIndex: "deliveries",
      },
      // {
      //   title: "Deliveries Remark",
      //   dataIndex: "deliveries_remark",
      // },
      {
        title: "Adjustment",
        dataIndex: "adjustments",
      },
      // {
      //   title: "Other Remark",
      //   dataIndex: "other_remark",
      // },
      {
        title: "Sales",
        dataIndex: "sales",
      },
      // {
      //   title: "Sales Remarks",
      //   dataIndex: "sales_remark",
      // },
      {
        title: "Closing Stock",
        dataIndex: "closing_stock",
      },
      // {
      //   title: "Closing Remarks",
      //   dataIndex: "closing_stock",
      // },
      {
        title: "Book Stock",
        dataIndex: "book_stock",
      },
      {
        title: "Variance",
        dataIndex: "variance",
      },
      {
        title: "Action",
        dataIndex: "inventory",
        slots: { customRender: "inventory" },
        align: 'center'
      },
    ]);

    const getData = (record: any, payload: any) => {
      let selected_month = moment(month.value).format(
        "YYYY-MM"
      );
      store
        .dispatch("Inventories/history",{selected_month,record,payload});
    };

    const cancel = () => {
      historyOpen.value = false;
    };

    const showRemarks = (record: any) => {
      const { deliveries_remark, sales_remark, other_remark, closing_stock_remark} = toRefs(form_fields);
      deliveries_remark.value = record.deliveries_remark;
      sales_remark.value = record.sales_remark;
      other_remark.value = record.other_remark;
      closing_stock_remark.value = record.closing_stock_remark;
      visible_remarks.value = true;
    };

    const filterRecord = (payload: any) => {
      getData(form.value, payload)
    }

    const showAdjustment = (record: any) => {
      const { id} = toRefs(form_fields);
      id.value = record.id;
      visible_adjustment.value = true;
    };
    
    onBeforeUpdate(() => {
     let record = items.value;
       if(record.data){
         record.data.length = 0;
       }
    });
    
    const items = computed(() => store.getters["Inventories/history_items"]);

    return {
      form,
      month,
      fields,
      items,
      visible_remarks,
      form_fields,
      visible_adjustment,
      getData,
      showRemarks,
      cancel,
      showAdjustment,
      filterRecord,
      historyOpen
    };
  },
});
